import ContactForm from "../../components/contact-form/contact-form.component"

const Contact = () => {
  return (
    <div>
      <ContactForm />
    </div>
  )
}

export default Contact